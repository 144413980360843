<template>
  <section class="top-cars-brands">
    <el-row align="middle" class="pg-0-1 mb-2" justify="space-between">
      <div>
        <span v-if="title" class="custom-headline custom-headline--level2">
          {{ title }}
        </span>
      </div>

      <div>
        <nuxt-link
          class="custom-link--blue underline bold"
          :to="dependency.productLine
            ? linkToParents
            : { name: getRouteName('cars') }"
        >
          {{ $t('catalog.allCars') }} >
        </nuxt-link>
      </div>
    </el-row>

    <ul class="mr-list">
      <li v-for="key in keys" :key="key" class="mr-list__item">
        <el-row>
          <div class="mr-1 mr-list__item-title bold">
            {{ key }}
          </div>

          <ul class="mr-list__item-brands">
            <li
              v-for="brand in groupedByName[key]"
              :key="brand.id"
              class="mr-list__item"
            >
              <nuxt-link :to="getSeoLinkBy(brand)">
                {{ brand.name }}
              </nuxt-link>
            </li>
          </ul>
        </el-row>
      </li>
    </ul>
  </section>
</template>

<script>
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import getSeoLinkBy from '../services/getSeoLinkBy';

export default {
  name: 'TopCarsBrands',

  props: {
    title: String,
    dependency: { type: Object, default: () => ({}) },
    value: { type: Array, required: true }
  },

  computed: {
    linkToParents () {
      const params = { lineId: get(this, 'dependency.productLine.slug') };
      return getSeoLinkBy(params, this, params);
    },

    groupedByName () {
      return groupBy(this.value, item => item.name[0].toUpperCase());
    },

    productLineName () {
      return get(this, 'dependency.productLine.name');
    },

    keys () {
      return Object.keys(this.groupedByName).sort();
    }
  },

  methods: {
    getSeoLinkBy ({ slug }) {
      const params = {
        carBrand: slug,
        lineId: get(this, 'dependency.productLine.slug')
      };

      return getSeoLinkBy(params, this, params);
    }
  }
};
</script>

<style scoped lang="scss">
.mr-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    min-width: 150px;
    display: block;
    padding: 10px;

    &-title {
      font-size: 1.2rem;
    }

    &-brands {
      padding: 4px 0 0;
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "top-cars-brands" },
    [
      _c(
        "el-row",
        {
          staticClass: "pg-0-1 mb-2",
          attrs: { align: "middle", justify: "space-between" },
        },
        [
          _c("div", [
            _vm.title
              ? _c(
                  "span",
                  { staticClass: "custom-headline custom-headline--level2" },
                  [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "custom-link--blue underline bold",
                  attrs: {
                    to: _vm.dependency.productLine
                      ? _vm.linkToParents
                      : { name: _vm.getRouteName("cars") },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("catalog.allCars")) +
                      " >\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "mr-list" },
        _vm._l(_vm.keys, function (key) {
          return _c(
            "li",
            { key: key, staticClass: "mr-list__item" },
            [
              _c("el-row", [
                _c("div", { staticClass: "mr-1 mr-list__item-title bold" }, [
                  _vm._v("\n          " + _vm._s(key) + "\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "mr-list__item-brands" },
                  _vm._l(_vm.groupedByName[key], function (brand) {
                    return _c(
                      "li",
                      { key: brand.id, staticClass: "mr-list__item" },
                      [
                        _c(
                          "nuxt-link",
                          { attrs: { to: _vm.getSeoLinkBy(brand) } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(brand.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }